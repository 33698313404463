<template>
  <div
      class="container inside-wrapper inside-wrapper-margin"
      id="case-study"
      data-aos="fade-up"
      data-aos-duration="1000"
  >

    <div class="row back-image munilogic back-white" style="">
        <div class="col-md-5 col-heading">
            <h2 class="heading wow fadeInDown text-blue">Munilogic<a href="http://www.munilogic.com/index.shtml"><span class="lnr lnr-link"></span></a></h2>
            <br>
            <p class="text-dark wow fadeInUp text-dark subheading text-justify">

                MuniLogic's Core System is a municipal management system consisting of a set of modules which provides the foundation for efficient operations and expanded functionality.<br><br> This project requires an update to use current technology as well as be able to reach a wider range of equipment.
            </p>
            <br><br>
            <router-link to="/Legacy"  class="web-blue"><span class="web-icon-blue"><i class="fa fa-mobile "></i> </span> <span class="web-text-blue text-bold">How Much Legacy Applications Cost</span></router-link>
            <br><br><br>
        </div>
    </div>
    <section class="case-study">

        <div class="row top-height">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-6">
                        <div class=" margin-top">

                            <h2 class="heding-title">1<span class="sub-title-pad sub-title-text">Challenge</span></h2>
                        </div>

                        <p class="text-dark wow fadeInUp text-justify">
                            Rapid growth put stress on the traditional methods of managing land development, zoning and code enforcement. Increased development increased the volume of paper- work making document management, storage and information retrieval exceedingly difficult and cumbersome. Customer service suffered and important matters sometimes fell between the cracks.


                            <br><br>
                        </p>

                    </div>
                    <div class="col-sm-4 col-sm-offset-1">
                        <div class="app-screens-mobile">
                            <img width="100%" src="../../assets/img/casestudy-munilogic/1.png">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-sm-offset-1">
                        <div class="app-screens-mobile hidden-xs">
                            <img width="100%" src="../../assets/img/casestudy-munilogic/2.png">
                        </div>
                    </div>
                    <div class="col-sm-6 col-sm-offset-1">
                        <div class=" margin-top-section">
                            <h2 class="heding-title">2<span class="sub-title-pad sub-title-text">Obstacles</span></h2>
                        </div>
                        <ul type="none" class="contactPoints">
                            <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Improve "right to know" effectiveness</span></li>
                            <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Answer questions about potential development issues</span></li>
                            <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Help the police identify any issues with a property before taking action</span></li>
                            <li>
                                <i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>
                                    Create monthly building and inspections reports
                                </span>
                            </li>

                        </ul>
                    </div>

                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="  margin-top">
                            <h2 class="heding-title">3<span class="sub-title-pad sub-title-text">Solution</span></h2>

                        </div>
                        <p class="text-dark wow fadeInUp text-justify">
                            Munilogic municipal management software provided the solution to the Township’s need to streamline the land development, zoning and code enforcement processes and also provided a 21st century approach to document management. Prior to the installation of the Munilogic system, the Township stored all documents in old fashioned metal filing cabinets. All township maps were stored in file drawers. The simplest inquiry often took a much as 30 minutes to find the information and answer.<br><br> The Township purchased the Munilogic core system that included the Property module, the communication package (e-mail, calendar, contacts), GIS mapping, reporting, document management and administration. In addition the subdivision, permitting and inspections, roadway, storm sewer, zoning and code enforcement modules were also installed. The implementation was completed in 2008 and the system has been operational for 18 months.
                            <br><br>
                        </p>
                    </div>
                    <div class="col-sm-4 col-sm-offset-1">
                        <div class="app-screens-mobile">
                            <img width="100%" src="../../assets/img/casestudy-munilogic/3.png">
                        </div>
                    </div>
                </div>

                <div class="col-sm-8  col-technologies text">
                    <h2 class="text-dark border-dotted-blue">Whichever services you require, we have the skills to match.</h2>
                    <router-link to="/contact" class="btn-red hvr-sweep-to-right hvr-sweep-to-right-red">Contact</router-link>
                </div>
    </div></div></section>

<div class="row contact-fourth back-red">
      <div class="col-sm-5 bottom-links text-left">
        <router-link to="/">
          <h2 class="heading text-light">
            <i class="fa fa-long-arrow-left"> </i> Home
          </h2>
        </router-link>
      </div>
      <div class="col-sm-2">
        <p></p>
      </div>
      <div class="col-sm-5 bottom-links text-right">
        <router-link to="/news">
          <h2 class="heading text-light">
            News <i class="fa fa-long-arrow-right"> </i>
          </h2>
        </router-link>
      </div>
    </div>


            </div>
</template>

<script>
export default {
  name: "work",
  async mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
</style>
